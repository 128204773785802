import React from 'react'
import PropTypes from 'prop-types'
import FrontasticImage from '../../atoms/frontasticImage'
import classnames from 'classnames'
import FullPageWidthWrapper from '../../molecules/Layout/FullPageWidthWrapper'

const Advantage = ({
    advantageItem, bgColor, textColor, itemColor,
}) => {
    if (!advantageItem || advantageItem.length === 0) {
        return null
    }

    const classList = [
        'my-comp flex content-between flex-wrap advantage-component',
    ]

    const style = {}
    const styleText = {}
    const styleBGColor = {}

    if (itemColor) {
        style.backgroundColor = itemColor
    }

    if (bgColor) {
        styleBGColor.backgroundColor = bgColor
    }

    if (textColor) {
        styleText.color = textColor
    }

    return (
        <FullPageWidthWrapper
            customClasses={classnames('relative advantages-component', {
                'py-comp': bgColor,
            })}
            style={styleBGColor}
        >
            <div className={'o-wrapper'}>
                <div className={'o-grid o-region'}>
                    <div className={'o-cell o-cell--12'}>
                        <div className={classList.join(' ')}>
                            {advantageItem.map((item) => (
                                <div className={'w-1/2 md:w-1/4 group'} style={style} key={item.description}>
                                    <div className={'flex w-full flex-col items-center'}>
                                        <div>
                                            <FrontasticImage media={item.icon.media} alt={item.description} width={30}/>
                                        </div>
                                        <div className={'desc'} style={styleText}>{item.description}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </FullPageWidthWrapper>
    )
}

Advantage.propTypes = {
    advantageItem: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.object,
        description: PropTypes.string,
    })).isRequired,
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
    itemColor: PropTypes.string,
}

export default Advantage
