import React from 'react';
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import PropTypes from 'prop-types';
import Advantage from '../../patterns/organisms/Advantages';

function AdvantageTastic({ data: { advantageItem, mode, bgColor, textColor, itemColor } }) {
  return (
    <Advantage
      advantageItem={advantageItem}
      mode={mode}
      bgColor={bgColor}
      textColor={textColor}
      itemColor={itemColor}
    />
  );
}

AdvantageTastic.propTypes = {
  data: PropTypes.object.isRequired,
};

export default tastify()(AdvantageTastic);
